import { useEffect } from 'react';
import styled from 'styled-components';

import HexagonLayer from '../layers/HexagonLayer';
import PhotoLayer from '../layers/PhotoLayer';
import SingleImageLayer from '../layers/singleImage/SingleImageLayer';
import MeasurementLayer from '../layers/MeasurementLayer';
import PointLayer from '../layers/PointLayer';
import PolygonLayer from '../layers/PolygonLayer';
import AoiLayer from '../layers/AoiLayer';
import TrainingDataLayer from '../layers/TrainingDataLayer';

import FilterHexagon from '../modifyLayers/FilterHexagon';
import FilterPoints from '../modifyLayers/points/FilterPoints';
import ModifyTrainingData from '../modifyLayers/ModifyTrainingData';
import HexagonInfo from './HexagonInfo';
import SidebarTemplate from './sidebarElements/SidebarTemplate';
import SidebarSummary from './summary/SidebarSummary';
import LayersDropdown from './sidebarElements/LayersDropdown';
import ModelResultDropdown from './sidebarElements/ModelResultDropdown';
import HexagonColorFilter from '@components/hexagonColorFilter/HexagonColorFilter';
import HexagonSummarySidebar from './summary/HexagonSummarySidebar';

import { makeHexagonLayer } from '@utils/map/hexagon.layer';
import { removePointLayer } from '@utils/map/point.layer';
import { useProject, ProjectMode, ModelType } from '@contexts/Project.context';

const LayerList = styled.ul`
	margin: 0;
	list-style: none;
	padding: 20px;
	font-size: 1rem;
	font-weight: 600;
	text-transform: capitalize;
	display: flex;
	flex-direction: column;
	row-gap: 10px;

	ul {
		margin: 5px 0 0 0px;
		padding: 0;
		list-style: none;
		display: flex;
		flex-direction: column;
		> li {
			> div {
				margin-bottom: 10px;
			}
			&:last-child > div {
				margin-bottom: 0;
			}
		}
		ul {
			margin: -10px 0 10px 36px;

			button {
				font-size: 0.85em;
			}
			input[type='checkbox'] {
				margin-right: 0;
			}
		}
	}
`;

const LayerListItem = styled.li`
	margin-top: ${p => (p.multiLayer ? '15px' : '0')};
`;

const ModelResultHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;
const ModelName = styled.div`
	font-size: 0.9rem;
	margin: 0;
	opacity: 0.7;
	font-weight: 300;
`;

const sidebarIds = {
	layerView: 'layerView',
	modifyHexagons: 'modifyHexagons',
	modifyPoints: 'modifyPoints',
	modifyPolygons: 'modifyPolygons',
	modifyTrainingData: 'modifyTrainingData',
	hexagonColorFilter: 'hexagonColorFilter',
	singleImageFilter: 'singleImageFilter',
};

/**
 * Renders the sidebars that are responsible for altering the presentation of the map.
 * anything like changing the styles, showing/hiding layers, filtering etc. should be done here.
 */
const LayersSideBar = ({ hexagonColor, setHexagonColor, maxZoomLevel }) => {
	const {
		modelType,
		pointLayer,
		polygonLayer,
		mapObject,
		hexagonInfo,
		project,
		projectMode,
		measurementLayerAdded,
		dispatch,
	} = useProject();

	useEffect(() => {
		if (modelType === ModelType.OBJECT_DETECTION && polygonLayer) {
			console.log('removing polygon layer');
			mapObject.removeLayer(polygonLayer);
			dispatch({ type: 'setPolygonLayer', payload: null });
		} else if (modelType === ModelType.SEGMENTATION && pointLayer) {
			console.log('removing point layer');
			removePointLayer();
			dispatch({ type: 'setPointLayer', payload: null });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modelType, pointLayer, polygonLayer]);

	const layers = [
		{
			key: 'surface-map',
			component: (
				<PhotoLayer
					name="Surface map"
					directory="dsm_tiles"
					zIndex={2}
					maxZoomLevel={maxZoomLevel}
					visible={false}
					layerId="dsmLayer"
				/>
			),
			show: projectMode === ProjectMode.ORTHOPHOTO,
		},
		{
			key: 'drone-photo',
			component: (
				<PhotoLayer
					name="Drone Photo"
					directory="orthophoto_tiles"
					checkDirectory={false}
					zIndex={4}
					maxZoomLevel={maxZoomLevel}
					layerId="orthophotoLayer"
				/>
			),
			show: projectMode === ProjectMode.ORTHOPHOTO,
		},
		{
			key: 'single-image',
			component: <SingleImageLayer />,
			show: projectMode === ProjectMode.SINGLE_IMAGE,
		},
		{
			key: 'measurement-layer',
			component: <MeasurementLayer />,
			show: measurementLayerAdded,
		},
	];

	const modifySidebars = [
		{
			zindex: 2,
			sidebarId: sidebarIds.modifyHexagons,
			header: 'Modify Hexagons',
			closeable: true,
			children: (
				<FilterHexagon
					hexagonColor={hexagonColor}
					setHexagonColor={setHexagonColor}
				/>
			),
		},
		{
			zindex: 2,
			sidebarId: sidebarIds.modifyPoints,
			header: 'Modify Points',
			closeable: true,
			children: <FilterPoints />,
		},
		{
			zindex: 2,
			sidebarId: sidebarIds.modifyPolygons,
			header: 'Modify Polygons',
			closeable: true,
			children: <FilterPoints />,
		},
		{
			zindex: 2,
			sidebarId: sidebarIds.modifyTrainingData,
			header: 'Modify Training Data',
			closeable: true,
			children: <ModifyTrainingData />,
		},
	];

	if (!mapObject) return null;

	return (
		<>
			<SidebarTemplate
				sidebarId={sidebarIds.layerView}
				zindex={1}
				header="Layers"
				headerDropdown={<LayersDropdown />}
				closeable={false}
				collapsible={true}>
				<LayerList>
					{layers.map(
						layer =>
							layer.show && (
								<LayerListItem key={layer.key}>
									{layer.component}
								</LayerListItem>
							)
					)}

					{modelType && (
						<LayerListItem multiLayer>
							<ModelResults
								sidebarIds={sidebarIds}
								hexagonColor={hexagonColor}
							/>
						</LayerListItem>
					)}
				</LayerList>

				<SidebarSummary project={project} />
			</SidebarTemplate>

			{modifySidebars?.map(sidebar => (
				<SidebarTemplate key={sidebar.sidebarId} {...sidebar} />
			))}

			{hexagonInfo && (
				<>
					<HexagonInfo />

					<HexagonSummarySidebar />

					<HexagonColorFilter
						sidebarId={sidebarIds.hexagonColorFilter}
					/>
				</>
			)}
		</>
	);
};

export default LayersSideBar;

const ModelResults = ({ sidebarIds, hexagonColor }) => {
	const { setActiveSidebar, pickedTask, features } = useProject();

	return (
		<>
			<div>Model results</div>

			<div className="mt-1">
				<ModelResultHeader>
					<ModelName>{pickedTask?.description}</ModelName>
					{features?.length > 0 && (
						<ModelResultDropdown
							setHexagonColorFilterActive={() =>
								setActiveSidebar({
									sidebarId: sidebarIds.hexagonColorFilter,
									parentId: sidebarIds.layerView,
								})
							}
						/>
					)}
				</ModelResultHeader>

				<ModelResultsList hexagonColor={hexagonColor} />
			</div>
		</>
	);
};

const ModelResultsList = ({ hexagonColor }) => {
	const { modelType, setActiveSidebar } = useProject();

	return (
		<ul>
			<LayerListItem>
				<AoiLayer />
			</LayerListItem>

			{modelType === ModelType.OBJECT_DETECTION && (
				<>
					<LayerListItem>
						<PointLayer
							setActiveSidebar={() =>
								setActiveSidebar({
									sidebarId: sidebarIds.modifyPoints,
									parentId: sidebarIds.layerView,
								})
							}
						/>
					</LayerListItem>

					<LayerListItem>
						<TrainingDataLayer
							layerId="trainingDataPointsLayer"
							featureType="annotation_points"
							setActiveSidebar={() => {
								setActiveSidebar({
									sidebarId: sidebarIds.modifyTrainingData,
									parentId: sidebarIds.layerView,
								});
							}}
						/>
					</LayerListItem>
				</>
			)}

			{modelType === ModelType.SEGMENTATION && (
				<>
					<LayerListItem>
						<PolygonLayer
							setActiveSidebar={() =>
								setActiveSidebar({
									sidebarId: sidebarIds.modifyPolygons,
									parentId: sidebarIds.layerView,
								})
							}
						/>
					</LayerListItem>
					<LayerListItem>
						<TrainingDataLayer
							layerId="trainingDataPolygonsLayer"
							featureType="annotation_polygon"
							setActiveSidebar={() =>
								setActiveSidebar({
									sidebarId: sidebarIds.modifyTrainingData,
									parentId: sidebarIds.layerView,
								})
							}
						/>
					</LayerListItem>
				</>
			)}

			<LayerListItem>
				<HexagonLayer
					makeHexagonLayer={makeHexagonLayer}
					hexagonColor={hexagonColor}
					setModifyHexagonMode={() =>
						setActiveSidebar({
							sidebarId: sidebarIds.modifyHexagons,
							parentId: sidebarIds.layerView,
						})
					}
				/>
			</LayerListItem>
		</ul>
	);
};
