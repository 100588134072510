import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineCloudDownload } from 'react-icons/ai';

import { downloadFile } from '@utils/files';
import { fetchUrl } from '@api';
import { useProject } from '@contexts/Project.context';

const ButtonContainer = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 2;
`;

const StyledDropdownItem = styled(Dropdown.Item)`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`;

const Export = () => {
	const { annotationMode, project, isDemo } = useProject();
	const { uuid, title } = project;

	return (
		!annotationMode &&
		uuid && (
			<ButtonContainer>
				<Dropdown>
					<Dropdown.Toggle
						variant="success"
						id="export-downloads-toggle">
						Export
					</Dropdown.Toggle>

					<Dropdown.Menu variant="dark">
						<GeojsonExport />
						<OrthophotoExport />
					</Dropdown.Menu>
				</Dropdown>
			</ButtonContainer>
		)
	);
};

export default Export;

const GeojsonExport = () => {
	const { project, isDemo, exportData } = useProject();

	const { title } = project;

	const handleClick = () => {
		const geojsonData = {
			url: `data:text/json;charset=utf-8,${encodeURIComponent(
				JSON.stringify(exportData)
			)}`,
			title,
			fileExtension: 'geojson',
		};

		downloadFile(geojsonData);
	};

	return (
		<StyledDropdownItem
			disabled={isDemo || !exportData}
			onClick={handleClick}>
			<AiOutlineCloudDownload /> <span>GeoJSON</span>
		</StyledDropdownItem>
	);
};

const OrthophotoExport = () => {
	const { project, isDemo } = useProject();
	const { title, uuid } = project;

	const { data } = useQuery({
		queryKey: ['project_orthophoto_signed_url', uuid],
		queryFn: () =>
			fetchUrl(`fileLink?key=${uuid}/odm_orthophoto/odm_orthophoto.tif`),
		enabled: !!uuid,
	});

	const orthophotoUrl = data?.url;
	const orthophotoData = {
		url: orthophotoUrl,
		title: `${title}_orthophoto`,
		fileExtension: 'tif',
	};

	return (
		<StyledDropdownItem
			disabled={!orthophotoUrl || isDemo}
			onClick={() => downloadFile(orthophotoData)}>
			<AiOutlineCloudDownload /> <span>Orthophoto</span>
		</StyledDropdownItem>
	);
};
